import React from "react"
const defaultState = {
  playful: false,
  contact: false,
  mobileMenu: false,
  togglePlayful: () => {},
  toggleContact: () => {},
  toggleMobileMenu: () => {},
}
const ThemeContext = React.createContext(defaultState)

class ThemeProvider extends React.Component {
  state = {
    playful: false,
    contact: false,
    mobileMenu: false,
  }
  togglePlayful = () => {
    let playful = !this.state.playful
    localStorage.setItem("playful", JSON.stringify(playful))
    this.setState({ playful })
  }
  toggleContact = () => {
    let contact = !this.state.contact

    if (this.state.contact) {
      this.setState({ mobileMenu: false })
    }

    this.setState({ contact })
  }
  toggleMobileMenu = () => {
    let mobileMenu = !this.state.mobileMenu
    this.setState({ mobileMenu })
  }
  componentDidMount() {
    const lsPlayful = JSON.parse(localStorage.getItem("playful"))
    if (lsPlayful) {
      this.setState({ playful: lsPlayful })
    }
  }
  render() {
    const { children } = this.props
    const { playful, contact, mobileMenu } = this.state
    return (
      <ThemeContext.Provider
        value={{
          playful,
          togglePlayful: this.togglePlayful,
          contact,
          toggleContact: this.toggleContact,
          mobileMenu,
          toggleMobileMenu: this.toggleMobileMenu,
        }}
      >
        {children}
      </ThemeContext.Provider>
    )
  }
}
export default ThemeContext
export { ThemeProvider }
